import React, { useState, useRef } from 'react'
import { ChromePicker } from 'react-color'
import useOnClickOutside from 'use-onclickoutside'
import styled from '../lib/styled'
import { Surface } from '../components/Surface'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { InfoBox } from '../components/InfoBox'
import { Button } from '../components/Button'
import { BrandLogoUpload } from '../components/BrandLogoUpload'

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 96px;
  @media(max-width:728px){
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
`
const Color = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  width: 50px;
  height: auto;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`
const ColorPicker = styled.div`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 3px;
  width: 100%;
  display: flex;
  cursor: pointer;
`

interface BrandSettingsProps {
  logoSrc: string
}

export const BrandSettings = (props: BrandSettingsProps) => {
  const wrapperRef = useRef<any>()
  const [brandColor, setBrandColor] = useState('#005EFF')
  const [pickerOpen, setPickerOpen] = useState(false)

  useOnClickOutside(wrapperRef, () => setPickerOpen(false))

  return (
    <Surface style={{ padding: 32 }}>
      <Wrapper>
        <div>
          <Heading style={{ marginBottom: 8 }}>Brand Settings</Heading>
          <Body style={{ maxWidth: 236, lineHeight: '24px' }}>
            Details about your business corporation and shares.
          </Body>
        </div>
        <div>
          <Body>
            Upload logo
            <Icon
              type={IconType.moreInfo}
              style={{ position: 'relative', left: 8, top: 4, cursor: 'pointer' }}
            />
          </Body>
          <InfoBox>This would be the logo displayed on the invoice</InfoBox>
          <BrandLogoUpload logoSrc={props.logoSrc} />
          <Body style={{ marginBottom: 12 }}>Brand color</Body>
          <div style={{ position: 'relative' }} ref={wrapperRef}>
            {pickerOpen ? (
              <div style={{ position: 'absolute', bottom: 50 }}>
                <ChromePicker
                  color={brandColor}
                  onChangeComplete={color => setBrandColor(color.hex)}
                />
              </div>
            ) : null}
            <ColorPicker onClick={() => setPickerOpen(!pickerOpen)} ref={wrapperRef}>
              <Color color={brandColor} />
              <div style={{ padding: 12 }}>
                <Heading size={HeadingSize.sm}>{brandColor}</Heading>
              </div>
            </ColorPicker>
          </div>
          <Button style={{ marginTop: 32 }}>SAVE CHANGES</Button>
        </div>
      </Wrapper>
    </Surface>
  )
}

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Link } from 'react-router-dom'
import { CardStatus } from '../components/Card'
import { CardBadge } from '../components/CardBadge'
import MediaQuery from 'react-responsive'
const CornerImage = styled.img`
  position: relative;
  left: 0px;
  top: 0px;
  width: 250px;
`
const HomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2em;
  margin-bottom: 50px;
  @media (max-width: 728px) {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.color.white};
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
`

const MailWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 2em;
`

const PhoneWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 2em;
`
const StatusWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  gap: 1em;
`
const Title = styled.div`
  font-size: 1.5rem;
  line-height: 32px;
  color: ${props => props.theme.color.independence};
  font-weight: 600;
`
const MailAddress = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.color.aliceBlue};
  border-radius: 8px;
  padding: 24px;
  gap: 0.4em;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: ${props => props.theme.color.independence};
`
const PhoneNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`
const Mail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
const AvailableBalance = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: ${props => props.theme.color.text.secondary};
`
const Phone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
const Balance = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 46px;
  letter-spacing: 0.947368px;
  color: ${props => props.theme.color.independence};
`

const NumberWrap = styled.div`
  background: ${props => props.theme.color.aliceBlue};
  display: flex;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 8px 8px 8px 1em;
`
const Number = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.4em;
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 38px;
  letter-spacing: 0.947368px;
  color: ${props => props.theme.color.primary};
`
export const OperatingAgreementHome = () => {
  return (
    <PageLayout title={'Operating Agreement'}>
      <HomeWrapper>
        <Wrapper>
          <CornerImage src="/images/mail-corner.svg" alt="" />
          <MailWrap>
            <Mail>
              <StatusWrap>
                <Title>{'Ready to draft an operating agreement for your LLC?'}</Title>
                <MediaQuery query="(min-width:728px">
                  <img src="/images/create-oa.svg" style={{ height: '30vh' }} alt="" />
                </MediaQuery>
                <MediaQuery query="(max-width:728px">
                  <img src="/images/create-oa.svg" style={{ height: '15vh' }} alt="" />
                </MediaQuery>
              </StatusWrap>
            </Mail>
            <Link
              style={{
                background: 'var(--primary)',
                borderRadius: '3px',
                padding: '11px 17px',
                color: 'var(--white)',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '15px',
                userSelect: 'none',
                letterSpacing: '1.31px',
                position: 'relative',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                border: '1px solid var(--primary)',
              }}
              to="/legal/startoa"
            >
              {'Create operating agreement'}
            </Link>
          </MailWrap>
        </Wrapper>
        <Wrapper>
          <CornerImage src="/images/mail-corner.svg" alt="" />
          <MailWrap>
            <Mail>
              <StatusWrap>
                <Title>{'Your operating agreement is ready.'}</Title>
                <MediaQuery query="(min-width:728px">
                  <img src="/images/view_oa.svg" style={{ height: '30vh' }} alt="" />
                </MediaQuery>
                <MediaQuery query="(max-width:728px">
                  <img src="/images/view_oa.svg" style={{ height: '15vh' }} alt="" />
                </MediaQuery>
              </StatusWrap>
            </Mail>
            <Link
              style={{
                background: 'var(--primary)',
                borderRadius: '3px',
                padding: '11px 17px',
                color: 'var(--white)',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '15px',
                userSelect: 'none',
                letterSpacing: '1.31px',
                position: 'relative',
                textTransform: 'uppercase',
                border: '1px solid var(--primary)',
                fontWeight: 'bold',
              }}
              to="/legal/operating-agreement-detail"
            >
              {'View operating agreement'}
            </Link>
          </MailWrap>
        </Wrapper>
      </HomeWrapper>
    </PageLayout>
  )
}

import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { MainLayout } from './components/MainLayout'
import { Home } from './pages/Home'
import { Purchase } from './pages/Purchase'
import { ConfirmDetails } from './pages/ConfirmDetails'
import { Company } from './pages/Company'
import { Settings } from './pages/Settings'
import { Perks } from './pages/Perks'
import { Start } from './pages/Start'
import { Legal } from './pages/Legal'
import { Documents } from './pages/Documents'
import { Shareholders } from './pages/Shareholders'
import { Share } from './pages/Share'
import { Payments } from './pages/Payments'
import { Accounting } from './pages/Accounting'
import { PaymentSetup } from './pages/PaymentSetup'
import { PaymentClients } from './pages/PaymentClients'
import { PaymentSettings } from './pages/PaymentSettings'
import { HomeModalTest } from './pages/HomeModalTest'
import { Tax } from './pages/Tax'
import { TaxFederal } from './pages/TaxFederal'
import { AccountingBookkeeping } from './pages/AccountingBookkeeping'
import { ConnectAccountingBookkeeping } from './pages/ConnectAccountingBookkeeping'
import { AccountingConsulting } from './pages/AccountingConsulting'
import { GetHelp } from './pages/GetHelp'
import { ReferFriendsStep1 } from './pages/ReferFriendsStep1'
import { ReferFriendsStep2 } from './pages/ReferFriendsStep2'
import { Billing } from './pages/Billing'
import { PaymentsSettings } from './pages/PaymentsSettings'
import { HomeState } from './pages/HomeState'
import { VirtualPhone } from './pages/VirtualPhone'
import { Communication } from './pages/Communication'
import { MyAccount } from './pages/MyAccount'
import { OperatingAgreementHome } from './pages/OperatingAgreementHome'
import { StartOA } from './pages/StartOA'
import { OperatingAgreementDetailCreate } from './pages/OperatingAgreementDetailCreate'
import { OperatingAgreementDetail } from './pages/OperatingAgreementDetail'

export const Router = () => {
  const signedIn = true

  useEffect(() => {
    document.body.style.backgroundColor = signedIn ? 'var(--primary)' : 'var(--white)'
    return () => {}
  }, [signedIn])

  return signedIn ? (
    <BrowserRouter>
      <MainLayout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/purchase">
            <Purchase />
          </Route>
          <Route exact path="/confirm-details">
            <ConfirmDetails />
          </Route>
          <Route exact path="/my-account/company">
            <Company />
          </Route>
          <Route exact path="/my-account/settings">
            <Settings />
          </Route>
          <Route exact path="/communication">
            <Communication />
          </Route>
          <Route exact path="/communication/mails"></Route>
          <Route exact path="/communication/phone">
            <VirtualPhone />
          </Route>

          <Route exact path="/market-place">
            <Perks />
          </Route>
          <Route exact path="/legal">
            {/* <Legal /> */}
            <OperatingAgreementHome />
          </Route>
          <Route exact path="/legal/documents">
            <Documents />
          </Route>
          <Route exact path="/legal/shareholders">
            <Shareholders />
          </Route>
          <Route exact path="/legal/share">
            <Share />
          </Route>
          <Route exact path="/legal/startoa">
            <OperatingAgreementDetailCreate />
          </Route>
          <Route exact path="/legal/operatingAgreement">
            <OperatingAgreementHome />
          </Route>
          <Route exact path="/legal/operating-agreement-detail">
            <OperatingAgreementDetail />
          </Route>
          <Route exact path="/payments">
            <Payments />
          </Route>
          <Route exact path="/payments/settings">
            <PaymentSettings />
          </Route>
          <Route exact path="/payments/setup">
            <PaymentSetup />
          </Route>
          <Route exact path="/payments/clients">
            <PaymentClients />
          </Route>
          {/*<Route exact path="/accounting">
            <Accounting />
  </Route>*/}
          <Route exact path="/accounting/bookkeeping">
            <AccountingBookkeeping />
          </Route>
          <Route exact path="/accounting">
            <ConnectAccountingBookkeeping />
          </Route>
          <Route exact path="/accounting/consulting">
            <AccountingConsulting />
          </Route>
          <Route exact path="/tax">
            <Tax />
          </Route>
          <Route exact path="/tax/federal">
            <TaxFederal />
          </Route>
          <Route exact path="/modal-test">
            <HomeModalTest />
          </Route>
          <Route exact path="/get-help">
            <GetHelp />
          </Route>
          <Route exact path="/refer-friends/step1">
            <ReferFriendsStep1 />
          </Route>
          <Route exact path="/refer-friends/step2">
            <ReferFriendsStep2 />
          </Route>
          <Route exact path="/setting">
            <PaymentsSettings />
          </Route>

          <Route exact path="/my-account/billing">
            <Billing />
          </Route>
          <Route exact path="/home-state">
            <HomeState />
          </Route>
          <Route exact path="/my-account">
            <MyAccount />
          </Route>

          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route exact path="/start">
          <Start />
        </Route>
        <Route path="*" render={() => <Redirect to="/start" />} />
      </Switch>
    </BrowserRouter>
  )
}

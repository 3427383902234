import React from 'react'
import styled from '../lib/styled'
import { SidebarItem } from './SidebarItem'
import { IconType } from './Icon'
import { Skeleton } from './Skeleton'
import { ReferFriendsItem } from './ReferFriendsItem'

const PADDING_TOP = 33
const PADDING_BOTTOM = 40

const Wrapper = styled.div`
  min-width: 293px;
`
const WrapperInner = styled.div`
  height: calc(100vh - ${PADDING_TOP + PADDING_BOTTOM}px);
  min-width: 293px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${PADDING_TOP}px;
  //padding-bottom: ${PADDING_BOTTOM}px;
`
const LogoWrapper = styled.div`
  margin-left: 34px;
  margin-bottom: 40px;
`
const Divider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.white};
  opacity: 0.25;
  margin: 20px 40px;
`
const LogoDetail = (props: { loading?: boolean }) => {
  return props.loading ? (
    <LogoWrapper style={{ marginLeft: 40 }}>
      <Skeleton primary big width="127px" />
    </LogoWrapper>
  ) : (
    <LogoWrapper>
      <img
        style={{ maxWidth: '175px', maxHeight: '55px' }}
        src="/images/shopify-formation.svg"
        alt="Start Global"
      />
    </LogoWrapper>
  )
}
export const Sidebar = (props: { loading?: boolean }) => (
  <Wrapper>
    <WrapperInner>
      <div>
        {/* {props.loading ? (
          <LogoWrapper style={{ marginLeft: 40 }}>
            <Skeleton primary big width="127px" />
          </LogoWrapper>
        ) : (
          <LogoWrapper>
            <img src="/images/logo.svg" alt="Start Global" style={{width:'27vh'}}/>
          </LogoWrapper>
        )} */}
        <LogoDetail loading={props.loading} />

        <SidebarItem
          to="/"
          includeRoutes={['/purchase', '/confirm-details']}
          icon={IconType.home}
          label="HOME"
          loading={props.loading}
        />
        {/* <SidebarItem
          to="/payments"
          icon={IconType.payments}
          label="PAYMENTS"
          loading={props.loading}
          //sup="beta"
          subitems={[
            { to: '/payments/clients', label: 'Clients' },
            { to: '/payments/settings', label: 'Settings' },
          ]}
        /> */}
        {/*<SidebarItem
          to="/communication"
          icon={IconType.communication}
          label="COMMUNICATION"
          loading={props.loading}
          subitems={[
            { to: '/communication/mails', label: 'Mails' },
            { to: '/communication/phone', label: 'Phone' },
          ]}
        />*/}

        <SidebarItem
          to="/accounting"
          icon={IconType.accounting}
          label="ACCOUNTING"
          loading={props.loading}
          subitems={[
            { to: '/accounting/bookkeeping', label: 'Bookkeeping' },
            //  { to: '/accounting/consulting', label: 'consulting' },
          ]}
        />
        <SidebarItem
          to="/tax"
          icon={IconType.taxIcon}
          label="TAX"
          loading={props.loading}
          subitems={[
            { to: '/tax/federal', label: 'Federal Tax' },
            // { to: '/tax/documents', label: 'Documents' }
            //{ to: '/', label: 'History' }
          ]}
        />

        <SidebarItem
          to="/legal"
          icon={IconType.legal}
          label="LEGAL"
          includeRoutes={['/legal/startoa', '/legal/operating-agreement-detail']}
          loading={props.loading}
          subitems={[
            // { to: '/legal/documents', label: 'Documents' },
            // { to: '/legal/shareholders', label: 'Shareholders' },
            // { to: '/legal/share', label: 'Share' },
            { to: '/legal/operatingAgreement', label: 'Operating Agreement' },
          ]}
        />
        {
          <SidebarItem
            to="/market-place"
            icon={IconType.marketplace}
            label="MARKETPLACE"
            loading={props.loading}
          />
        }

        <SidebarItem
          to="/my-account"
          icon={IconType.setting}
          label="MY ACCOUNT"
          loading={props.loading}
          subitems={[
            { to: '/my-account/company', label: 'Business' },
            { to: '/my-account/settings', label: 'Personal' },
            { to: '/my-account/billing', label: 'Billing' },

            //{ to: '/', label: 'History' }
          ]}
        />

        {/*<SidebarItem
          to="/company"
          icon={IconType.company}
          label="BUSINESS"
          loading={props.loading}
        />
        <SidebarItem
          to="/billing"
          icon={IconType.setting}
          label="BILLING"
          loading={props.loading}
        />*/}

        {/*<MediaQuery query="(min-width:728px)">
     </MediaQuery> */}
      </div>

      <div>
        {/* {props.loading ? null : <Divider />}
        {<SidebarItem
          to="/refer-friends/step1"
          icon={IconType.ReferFriends}
          label="REFER FRIENDS"
          loading={props.loading}
        />} */}
        <SidebarItem to="/get-help" icon={IconType.chat} label="GET HELP" loading={props.loading} />
      </div>
    </WrapperInner>
  </Wrapper>
)

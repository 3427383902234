import React, { useEffect } from 'react'
import anime from 'animejs'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import { StartFormLayout, StartFormProps } from '../StartFormLayout'
import { useStartForm } from '../StartFormProvider'
import { DecisionMakingForm, FormData } from '../../Forms/OperatingAgreement/DecisionMakingForm'

const contentId = 'about-company-content'
const cornerImageId = 'about-company-corner-image'
const cornerImageBuilding1Id = 'about-company-corner-image-building-1'
const cornerImageBuilding2Id = 'about-company-corner-image-building-2'
const cornerImageBuilding3Id = 'about-company-corner-image-building-3'

const AgreementWrapper = styled.div`
  background: #f2f7ff;
  border: 1px solid rgba(0, 94, 255, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 30px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
`

const SaveButton = styled.div`
  background: #ffffff;
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #757a89;
  cursor: pointer;
  padding: 10px 58px;
`

const PreviewButton = styled.div`
  border: #005eff;
  background: #005eff;
  border-radius: 8px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 34px;
  cursor: pointer;
`
export const DecisionMaking = (props: StartFormProps<FormData>) => {
  const startForm = useStartForm()
  const formHook = useForm<FormData>({ defaultValues: startForm.decisionMakingForm })
  const onSubmit = formHook.handleSubmit(values => {
    startForm.setDecisionMakingForm(values)
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.onSubmit(values), 500)
  })
  const goBack = () => {
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.goBack(), 500)
  }

  return (
    <>
      <StartFormLayout
        title={'Decision making'}
        description={'Add details about your members and other settings.'}
        contentId={contentId}
        onNextClick={onSubmit}
        onPrevClick={goBack}
        sidebarImage={
          <div id={cornerImageId}>
            <AgreementWrapper>
              <img
                style={{
                  width: '30px',
                  height: '30px',
                  marginTop: '-37px',
                  marginLeft: '-5px',
                  position: 'absolute',
                }}
                src="/images/agreement.svg"
              />
              <Wrapper>
                <label style={{ fontSize: '20px', lineHeight: '100%', color: '#444C66' }}>
                  LLC Operating Agreement
                </label>
                <label style={{ fontSize: '16px', lineHeight: '150%', color: '#757A89' }}>
                  A document that customizes the terms of a limited liability company according to
                  the specific needs of its owners.
                </label>
                <label style={{ fontSize: '16px', lineHeight: '150%', color: 'var(--primary)' }}>
                  Know more
                </label>
                {/* <PreviewButton>SEE DOCUMENT PREVIEW</PreviewButton> */}
              </Wrapper>
            </AgreementWrapper>
          </div>
        }
      >
        <DecisionMakingForm onSubmit={onSubmit} formHookProps={formHook} />
      </StartFormLayout>
    </>
  )
}

import React, { useEffect } from 'react'
import { ThemeProvider } from 'emotion-theming'
import { Router } from './Router'
import { theme } from './lib/styled'

const loadLink = function(type: string | null, rel: string, href: string, sizes?: string) {
  if (!href) {
    return
  }
  const headID = document.getElementsByTagName('head')[0]
  const link = document.createElement('link')
  if (type) {
    link.type = type
  }
  if (rel) {
    link.rel = rel
  }
  //  if(sizes) { link.sizes = sizes;}
  headID.appendChild(link)
  link.href = href
}

const App: React.FC = () => {
  useEffect(() => {
    loadLink(null, 'shortcut icon', `./favicons/favicon.ico`)
    loadLink(null, 'icon', `./favicons/favicon.ico`, '16x16 32x32')
    loadLink(null, 'image/png', `./favicons/favicon-32x32.png`, '32x32')
    loadLink(null, 'image/png', `./favicons/favicon-16x16.png`, '16x16')
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  )
}

export default App

import React, { useState } from 'react'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { BankAccountDetails } from '../components/BankAccountDetails'
import { Input } from '../components/Input'
import { Select } from '../components/Select'
import { TransactionsTable } from '../components/TransactionsTable'
import MediaQuery from 'react-responsive'

const CornerImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
`
const BankDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-bottom: 48px;
`
const BackIcon = styled.div`
  background-color: ${props => props.theme.color.primary};
  width: 105px;
  height: 105px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
`
const BankDetail = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 80%;
  }
`
const StripeIcon = styled.img`
  width: 46px;
  height: 46px;
`
const SurfaceBox = styled(Surface)`
  padding: 32px;
  position: relative;
  margin-bottom: 0;
  button {
    text-transform: uppercase;
    padding: 15px 34px;
    margin-left: 8px;
  }
`
const HeadingAccount = styled(Heading)`
  color: var(--independence);
  line-height: 42px;
  margin-bottom: 4px;
`
const BankDetails = styled(Body)`
  line-height: 30px;
`
const ManageHeading = styled(Heading)`
  color: var(--independence);
  margin-bottom: 4px;
`
const DocumentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  margin-bottom: 55px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const DocumentBox = styled.div`
  @media (min-width: 768px) {
    width: 100%;
    max-width: 33.33%;
    padding: 0 25px 0 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 25px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
`
const DocumentWrapper = styled.div`
  margin-top: 20px;
`
const Practices = styled.div`
  background-color: #eafff3;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 12px 15px;
  margin-bottom: 32px;
  margin-left: 8px;
  margin-right: 8px;
`
const SecurityIcon = styled.img`
  width: 31px;
  height: 39px;
  margin-right: 14px;
`
const TransactionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0px 32px 0;
`
const TransactionsItems = [
  {
    transactionsName: 'Digital Ocean',
    acDetails: 'Silicon Valley Bank xxxx9079',
    issuedOn: 'Jun 21, 2020',
    amount: { price: -100 },
    email: 'alex@email.com',
    balance: [{ title: 'something', price: 1987.07 }],
    notes: '',
  },
  {
    transactionsName: 'DNS simple',
    acDetails: 'Chase xxxx29793',
    issuedOn: 'Jun 21, 2020',
    amount: { price: -660.25 },
    email: 'alex@email.com',
    balance: [{ title: 'something', price: 2087.07 }],
    notes: 'This is important',
  },
  {
    transactionsName: 'Loan interest',
    acDetails: 'Chase xxxx29793',
    issuedOn: 'Jun 21, 2020',
    amount: { price: +100 },
    email: 'alex@email.com',
    balance: [{ title: 'something', price: 2747.07 }],
    notes: '',
  },
]
export const ConnectAccountingBookkeeping = () => {
  const [show, setShow] = useState(false)

  return (
    <PageLayout title="Bookkeeping">
      <>
        {!show && (
          <SurfaceBox>
            <CornerImage src="/images/purchase-corner.svg" alt="" />
            <BankDetailsWrapper>
              <MediaQuery query="(min-width:728px)">
                <BackIcon>
                  <StripeIcon src="/images/bank-icon.svg" />
                </BackIcon>
              </MediaQuery>
              <BankDetail>
                <HeadingAccount size={HeadingSize.lg}>Connect your bank account</HeadingAccount>
                <BankDetails size={BodySize.lg}>
                  Easily connect your existing bank account with startglobal and keep track of all
                  your transactions and accounting.
                </BankDetails>
              </BankDetail>
            </BankDetailsWrapper>
            <MediaQuery query="(min-width:728px)">
              <DocumentBlock>
                <DocumentBox>
                  <Icon type={IconType.done} size={28} />
                  <DocumentWrapper>
                    <ManageHeading size={HeadingSize.md}>Accounting</ManageHeading>
                    <Body>Keep track of your transactions to help with your accounting.</Body>
                  </DocumentWrapper>
                </DocumentBox>
                <DocumentBox>
                  <Icon type={IconType.done} size={28} />
                  <DocumentWrapper>
                    <ManageHeading size={HeadingSize.md}>Tax Estimates </ManageHeading>
                    <Body>We'll notify you about estimated tax payments.</Body>
                  </DocumentWrapper>
                </DocumentBox>
                <DocumentBox>
                  <Icon type={IconType.done} size={28} />
                  <DocumentWrapper>
                    <ManageHeading size={HeadingSize.md}>Filing estimates </ManageHeading>
                    <Body>Easily estimate your annual preparation and filing costs.</Body>
                  </DocumentWrapper>
                </DocumentBox>
              </DocumentBlock>
            </MediaQuery>
            <MediaQuery query="(max-width:728px)">
              <DocumentBlock>
                <DocumentBox>
                  <Icon type={IconType.done} size={28} />
                  <DocumentWrapper>
                    <ManageHeading size={HeadingSize.md}>Accounting</ManageHeading>
                    <Body>Keep track of your transactions to help with your accounting.</Body>
                  </DocumentWrapper>
                </DocumentBox>
                <DocumentBox>
                  <Icon type={IconType.done} size={28} />
                  <DocumentWrapper>
                    <ManageHeading size={HeadingSize.md}>Tax Estimates </ManageHeading>
                    <Body>We'll notify you about estimated tax payments.</Body>
                  </DocumentWrapper>
                </DocumentBox>
                <DocumentBox>
                  <Icon type={IconType.done} size={28} />
                  <DocumentWrapper>
                    <ManageHeading size={HeadingSize.md}>Filing estimates </ManageHeading>
                    <Body>Easily estimate your annual preparation and filing costs.</Body>
                  </DocumentWrapper>
                </DocumentBox>
              </DocumentBlock>
            </MediaQuery>
            <Practices>
              <SecurityIcon src="/images/security-icon.svg" />
              <Heading size={HeadingSize.sm}>
                We use bank level security in our servers and state of the art security practices
              </Heading>
            </Practices>
            <Button
              onClick={() => {
                setShow(false)
              }}
            >
              connect bank
            </Button>
          </SurfaceBox>
        )}
        {!!show && (
          <>
            <BankAccountDetails
              title="Silicon Valley Bank "
              acNumber="xxxx9079"
              date="Jul 24, 2020"
            />
            <BankAccountDetails title="Chase " acNumber="xxxx29793" date="Mar 24, 2021" />
            <TransactionsWrapper>
              <Heading>Transactions</Heading>
            </TransactionsWrapper>
            <InputWrapper>
              <Input
                fullWidth
                placeholder="Search an Invoice"
                addonStart={{ content: <Icon type={IconType.search} /> }}
              />
              <Select
                width="155px"
                wrapperStyle={{ marginLeft: 20 }}
                options={[{ label: 'Show all', value: 'ALL' }]}
              />
            </InputWrapper>
            <TransactionsTable items={TransactionsItems} />
          </>
        )}
      </>
    </PageLayout>
  )
}

import React, { useState, useRef, useEffect, useContext } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import useOnClickOutside from 'use-onclickoutside'
import { useGetFormHook, FormProps } from '../../lib/form'
import styled from '@emotion/styled'
import { Form } from '../Form'
import { Input } from '../Input'
import { TextArea } from '../TextArea'
import { ClientSelect } from '../ClientSelect'
import { Checkbox } from '../Checkbox'
import { DatePicker } from '../DatePicker'
import { TextButton } from '../TextButton'
import { Heading, HeadingSize } from '../Typography/Heading'
import { Select } from '../Select'
import Collapsible from 'react-collapsible'
import { Icon, IconType } from '../Icon'
import { RadioList } from '../RadioList'
import { StringLiteralLike } from 'typescript'
import { BodySize } from '../Typography/Body'
import { InputSelect } from '../InputSelect'


const ItemMenuButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .icon-color-part {
    fill: #757a89;
  }
  :hover {
    .icon-color-part {
      fill: #005eff;
    }
  }
`
const DeleteButton = styled.div<{ open: boolean }>`
  background-color: white;
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  padding: 0.75em;
  box-shadow: 0 10px 1em 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 0;
  top: 28px;
  display: ${props => (props.open ? 'inherit' : 'none')};
`
const Label = styled.div`
  font-size: 1.25em;
  line-height: 23px;
  color: #444c66;
  font-style: normal;
  font-weight: 600;
`

const ItemMenuButton = (props: { onClickRemove: () => void }) => {
  const wrapperRef = useRef<any>()
  const [menuOpen, setMenuOpen] = useState(false)

  useOnClickOutside(wrapperRef, () => setMenuOpen(false))

  return (
    <ItemMenuButtonWrapper onClick={() => setMenuOpen(true)}>
      <svg width="1em" height="4px" viewBox="0 0 16 4" version="1.1" className="menu-item-icon">
        <g id="Clean" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            className="icon-color-part"
            id="Remove-Item"
            transform="translate(-1408.000000, -561.000000)"
          >
            <g id="Group-7" transform="translate(932.000000, 271.000000)">
              <g id="Group-3" transform="translate(476.000000, 290.000000)">
                <circle id="Oval" cx="2" cy="2" r="2"></circle>
                <circle id="Oval" cx="8" cy="2" r="2"></circle>
                <circle id="Oval" cx="14" cy="2" r="2"></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <DeleteButton open={menuOpen} ref={wrapperRef} onClick={props.onClickRemove}>
        <Body color={'#f02f2f'} style={{ whiteSpace: 'nowrap' }}>
          Remove Item
        </Body>
      </DeleteButton>
    </ItemMenuButtonWrapper>
  )
}


export type FormData = {

  instance: number
  lastUpdated: string
  pendingFrom: number
  status: string
  documentId : number

  companyName: string
  companyRegisteredState: string
  companyStreetAddress: string
  companyCity: string
  companyZipCode: string
  oaEffectiveDate?: Date

  llcPurpose: string
  managementType: string
  registeredAgentName: string
  registeredAgentState: string
  registeredAgentAddress: string
  registeredAgentCity: string
  registeredAgentZipCode: string
  allowLlcToApproveOfficials: string

  oaMemberDTOList: Member[]
  profitDistributionFrequency: string
  fiscalYear: string
  irsTaxTreatment: string

  votingRight: string
  memberDecision: string
  decisionToAddNewMember: string
  decisionToRemoveMember: string
  decisionToAddContribution: string
  decisionToDissolveLLC: string
  decisionToAppointManager: string
  decisionToMakeBoardDecision: string

  decisionToAmendOA: string
  daysToBuyWtdrwnIntrst: number
  daysToBuyIntrstAftrJntPrd: number
  oaMembershipInterestSplit: string
  removeMbrByOthrMbr: string
  voteToDissolve: string
  noticePrdToDissolution: number

}

interface MemberDetailsWrapperProps {
  onClick: any;
  currentStatus: string;
  openType: number;
  data: any;
  reload: any;
  id: number;
  member: any
}
const WrapperList = styled.div`
  background-color: white;
  border: 1px solid #d4ddeb;
  border-radius: 0.5em;
  padding:18px;
  margin-bottom: 1em;
`
const WraBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
const AddItemWrapper = styled.div`
@media (min-width: 728px) {
  margin-top: 16px;
}
`
const CheckBoxWrapper = styled.div`
display: flex;
flex-direction: column;
@media (min-width: 728px) {
  margin-top: 32px;
}

`
const Button = styled.div`
  background: white;;
  border: 1px solid #005eff;;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 4%);
  border-radius: 0.5em;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 21px;
  color: #005eff;;
  margin-bottom: 2em;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
`
interface BodyProps {
  size?: BodySize;
  light?: boolean;
  dark?: boolean;
  bold?: boolean;
  color?: string;
  textAlign?: boolean
}
const Body = styled.div<BodyProps>`
  font-size: ${props => props.size || BodySize.md}px;
  font-weight: ${props => (props.bold ? 700 : 400)};
  text-align: ${props => props.textAlign && 'end'};
  color: ${props =>
    props.color ||
    (props.light
      ? 'white'
      : props.dark
        ? '#434b65'
        : '#757a89')};
    margin-top: 0.75em;
    line-height: 1.25em;
    margin-bottom: 0.75em;
    @media (max-width: 728px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5em;
    }
`;

const FormWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
@media (max-width: 728px) {
  gap: 1em;
}
`

const NameWrapper = styled.div`
@media (max-width: 728px) {
  gap: 1.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 728px) {
  gap: 1em;
  display: flex;
flex-direction: row;
}
`
const CheckboxLabel = styled(Body)`
  font-size: 1em;
  margin-left: 1em;
`

const ItemWrapper = styled.div`
  display: grid;
  // grid-template-columns: 4fr 2fr  0.3fr;
  grid-column-gap: 24px;
  margin-bottom: 1em;
  pointer-events: auto !important;

`

type Member = {
  firstName: string
  lastName: string
  email: string
  cashContribution: boolean
  nonCashContribution: boolean
  cashContributionAmount: string
  oaNonCashContributionDTOList: Contribution[]
  ownershipPercentage: number
  status: string
  address: string
  state : string
  country : string
  city : string
  zipCode: string
}

type Contribution = {
  contributionType: string
  contributionAmount: string
}
const ItemWrapperContribution = styled.div`
display: grid;
grid-column-gap: 24px;
grid-template-columns: 4fr 2fr  0.3fr;
margin-bottom: 1em;

`
// export const ContributionArray = ({ nestIndex, control, register, errors }) => {
//   const { fields, remove, append } = useFieldArray({
//     control,
//     name: `oaMemberDTOList[${nestIndex}].oaNonCashContributionDTOList`
//   });
//   return (
//     <AddItemWrapper>
//       {fields.map((item, j) => {
//         return (
//           <ItemWrapperContribution>
//             <Input
//               // error={
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j].contributionType &&
//               //   errors.members[nestIndex]?.contributions[j].contributionType?.message
//               // }
//               fullWidth
//               formStyle
//               placeholder="Contribution Type"
//               {...register(`oaMemberDTOList.${nestIndex}.oaNonCashContributionDTOList.${j}.contributionType`, { required: 'Required' })}
//             />
//             <Input
//               type="number"
//               fullWidth
//               formStyle
//               placeholder="$0.00"
//               // error={
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j].amount &&
//               //   errors.members[nestIndex]?.contributions[j].amount?.message
//               // }
//               {...register(`oaMemberDTOList.${nestIndex}.oaNonCashContributionDTOList.${j}.contributionAmount`, { required: 'Required' })}
//             />
//             <ItemMenuButton onClickRemove={() => remove(j)} />
//           </ItemWrapperContribution>
//         )
//       })}
//       <ItemWrapper createMode={false}>
//         <TextButton
//           style={{ fontSize: 14 }}
//           onClick={() =>
//             append({
//               contributionType: "",
//               contributionAmount: ""
//             })
//           }
//         >
//           {LanguageUtil.get("add-contribution")}
//         </TextButton>
//         <div />
//         <div />
//       </ItemWrapper>
//     </AddItemWrapper>
//   );
// }
export const OperatingAgreementForm = (
  props: FormProps<FormData> & { initialValues : any, disabled?: boolean; open: boolean; createMode: boolean; publicMode: boolean }
) => {
  const { register, errors , onSubmit, control, watch, setValue } = useGetFormHook(props)
  const { fields, append, remove } = useFieldArray({ control, name: 'oaMemberDTOList' })
  const members = watch('oaMemberDTOList')?.length ? watch('oaMemberDTOList') : []
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [llcType, setLLCType] = useState('');
  const [contributionType, setContributionType] = useState('CASH_CONTRIBUTION');
  const [masterdata, setMasterdata] = useState({} as any);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editable, setEditable] = useState(false);
  const [openType, setOpen] = useState(1);
  const [data, setData] = useState<any>({});
  const [reloadData, setReloadData] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState(props.initialValues);
  const [cashContribution, setCashContribution] = useState(false);
  const [nonCashContribution, setNonCashContribution] = useState(false);
  useEffect(() => {
    setLLCType(props.initialValues.managementType);
  }, []);

  const handleAccordianChange = (type: number) => {
    if (type === openType) {
      setOpen(-1)
    } else {
      setOpen(type)
    }
  }
  useEffect(() => {
  }, [selectedVersion?.oaMemberDTOList]);

  const onLLCTypeSelection = (value: string) => {
    setValue("managementType", value)
    setLLCType(value);
  }

  const onVotingRights = (value: string) => {
    setValue("votingRight", value)
  }


  const removeMember = (pos: number) => {
    selectedVersion?.oaMemberDTOList.splice(pos, 1);
  }
  const onDecisionTypeSelection = (value: string) => {
    setValue("memberDecision", value)
    setValue("decisionToAddNewMember", value)
    setValue("decisionToRemoveMember", value)
    setValue("decisionToAddContribution", value)
    setValue("decisionToDissolveLLC", value)
    setValue("decisionToAppointManager", value)
    setValue("decisionToMakeBoardDecision", value)
  }
  const onDecisionToAddNewMember = (value: string) => {
    setValue("decisionToAddNewMember", value)
  }
  const onDecisionToRemoveMember = (value: string) => {
    setValue("decisionToRemoveMember", value)
  }
  const onDecisionToAddContribution = (value: string) => {
    setValue("decisionToAddContribution", value)
  }
  const onDecisionToDissolveLLC = (value: string) => {
    setValue("decisionToDissolveLLC", value)
  }
  const onDecisionToAppointManager = (value: string) => {
    setValue("decisionToAppointManager", value)
  }
  const onDecisionToMakeBoard = (value: string) => {
    setValue("decisionToMakeBoardDecision", value)
  }

  function onClickCashContribution(value: boolean, i: number) {
    setCashContribution(value);
    setValue(`oaMemberDTOList.${i}.cashContribution`, value)
  }
  function onClickNonCashContribution(value: boolean, i: number) {
    setNonCashContribution(value);
    setValue(`oaMemberDTOList.${i}.nonCashContribution`, value)
  }

  return (
    <Form
      onSubmit={onSubmit}
      style={props.disabled ? { pointerEvents: 'none' } : undefined}
      hideSubmit={true}
    >
      <FormWrapper id='basicDetails'>
        <Label id='basic'>Basic Details</Label>
        <Input
          label={'Name of the LLC'}
          error={errors.companyName && errors.companyName.message}
          topSpace
          bottomSpace
          fullWidth
          formStyle
          disabled={true}
          defaultValue={'StartGlobal LLC'}
          {...register("companyName", {
          }
          )}
        />
        <Select
          label={'State of LLC Formation'}
          style={{ width: '100%' }}
          formStyle
          disabled={true}
          error={errors.companyRegisteredState && errors.companyRegisteredState.message}
          defaultValue={'Delaware'}
          onSelectOption={(value) => {
            setValue('companyRegisteredState', value)
          }}
          options={[{ label: 'Delaware', value: 'DELAWARE' }, { label: 'Wyoming', value: 'WYOMING' }]}
          {...register("companyRegisteredState",
          )}
        />
        <Input
          label={"LLC's primary business address"}
          error={errors.companyStreetAddress && errors.companyStreetAddress.message}
          topSpace
          fullWidth
          disabled={true}
          formStyle
          defaultValue={'340 S LEMON AVE #3696'}
          {...register("companyStreetAddress", {
          }
          )}
        />
        <div style={{ display: 'flex', gap: '1em' }}>
          <Input
            label={'City'}
            error={errors.companyCity && errors.companyCity.message}
            topSpace
            fullWidth
            disabled={true}
            formStyle
            defaultValue={'Walnut'}
            {...register("companyCity", {
            })}
          />
          <Input
            label={'Zip Code'}
            error={errors.companyZipCode && errors.companyZipCode.message}
            fullWidth
            topSpace
            formStyle
            bottomSpace
            defaultValue={'91789'}
            disabled={true}
            {...register("companyZipCode", {
            })}
          />
        </div>
        <Controller
            name="oaEffectiveDate"
            control={control}
            as={
              <DatePicker
                label="When will this operating agreement take effect?"
                error={errors.oaEffectiveDate && errors.oaEffectiveDate.message}
                bottomSpace
                rightSpace
                formStyle
                fullWidth
                calendarWrapperStyle={{
                  position: 'absolute',
                  left: 0,
                }}
              />
            }
          />
        <TextArea
        label={'Purpose of LLC (100 chars)'}
        error={errors.llcPurpose && errors.llcPurpose.message}
        fullWidth
        rows={4}
        bottomSpace
        maxLength={100}
        defaultValue={'To perform or engage in any and all activities and/or businesses for which limited liability companies may be engaged under the Statutes.'}
        {...register("llcPurpose", { required: "Required" })}
        />
        <RadioList
          name="managementType"
          label={'Is the LLC Member-managed or Manager-managed?'}
          bottomSpace
          options={[{ label: 'Member-managed', value: 'MEMBER_MANAGED' }, { label: 'Manager-managed', value: 'MANAGER_MANAGED' }]}
          value={'MEMBER_MANAGED'}
          onSelect={onLLCTypeSelection}
          rowSpan={true}
          error={errors.managementType && errors.managementType.message}
        />
        <Input
          label={'Name of the Registered agent'}
          error={errors.registeredAgentName && errors.registeredAgentName.message}
          fullWidth
          bottomSpace
          disabled={true}
          defaultValue={'Harvard Business Services, Inc'}
          formStyle
          {...register("registeredAgentName", { pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" }  })}
        />
         <Select
          label={'State of Registered agent'}
          style={{ width: '100%' }}
          formStyle
          error={errors.registeredAgentState && errors.registeredAgentState.message}
          disabled={true}
          defaultValue={'Delaware'}
          onSelectOption={(value) => {
            setValue('registeredAgentState', value)
          }}
          options={[{ label: 'Delaware', value: 'DELAWARE' }, { label: 'Wyoming', value: 'WYOMING' }]}
          {...register("registeredAgentState", {  })}
        />
        <Input
          label={'Address of the Registered agent'}
          error={errors.registeredAgentAddress && errors.registeredAgentAddress.message}
          topSpace
          fullWidth
          disabled={true}
          defaultValue={'16192 Coastal Highway'}
          formStyle
          {...register("registeredAgentAddress", {  pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" }  })}
        />
        <div style={{ display: 'flex', gap: '1em' }}>
        <Input
            placeholder={'City'}
            error={errors.registeredAgentCity && errors.registeredAgentCity.message}
            fullWidth
            topSpace
            formStyle
            disabled={true}
            defaultValue={'Lewes'}
            rightSpace
            {...register("registeredAgentCity", { pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" } })}
          />
          <Input
            placeholder={'Zip Code'}
            type="text"
            error={errors.registeredAgentZipCode && errors.registeredAgentZipCode.message}
            fullWidth
            formStyle
            defaultValue={'19958'}
            topSpace
            bottomSpace
            disabled={true}
            {...register("registeredAgentZipCode", { })}
          />
        </div>
        <Select
          label={'Should the LLC be allowed to appoint officers, such as a President, Secretary or Treasurer?'}
          style={{ width: '100%' }}
          formStyle
          error={errors.allowLlcToApproveOfficials && errors.allowLlcToApproveOfficials.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue('allowLlcToApproveOfficials', value)
          }}
          options={[{ label: 'Yes (recommended)', value: 'YES' }, { label: 'No', value: 'NO' }]}
          {...register("allowLlcToApproveOfficials")}
        />
        <Label style={{ marginBottom: '24px' ,marginTop:'24px'}}>Members</Label>
        { fields.map((item, i) => {
          return (
            <ItemWrapper>
              <div >
                <WrapperList>
                  <WraBlock onClick={() => handleAccordianChange(i)}>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      <img src="/icons/member.svg" alt="" />
                      {item.firstName != undefined && <Heading style={{ fontSize: '18px', color: '#444C66' }}> {item.firstName + ' ' + item.lastName} </Heading>}
                    </div>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      {props.publicMode && <img onClick={() => removeMember(i)} style={{ width: '1em', height: '1em' }} src="/icons/trash.svg" />}
                      <Icon
                        type={openType === i ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge} />
                    </div>
                  </WraBlock>
                  <Collapsible open={openType === i} easing="ease" trigger={''} transitionTime={150}>
                    <div>
                      <Body
                        size={BodySize.sm}
                        style={props.disabled ? { pointerEvents: 'none' } :{ pointerEvents: 'auto'}}
                      >
                        {
                          <>
                          <NameWrapper>
                            <Input
                              label={'First Name'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].firstName &&
                                errors.oaMemberDTOList[i].firstName?.message}
                              topSpace
                              fullWidth
                              formStyle
                              defaultValue={'Jonnathan'}
                              {...register(`oaMemberDTOList.${i}.firstName`, {
                              })} 
                            />
                            <Input
                              label={'Last Name'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].lastName &&
                                errors.oaMemberDTOList[i].lastName?.message}
                              topSpace
                              fullWidth
                              formStyle
                              defaultValue={'Doe'}
                              {...register(`oaMemberDTOList.${i}.lastName`, {
                              })} 
                              />
                          </NameWrapper>
                            <Input
                              label={'Email'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].email &&
                                errors.oaMemberDTOList[i].email?.message}
                              topSpace
                              fullWidth
                              formStyle
                              defaultValue={'jonnathan@abcInc.co'}
                              {...register(`oaMemberDTOList.${i}.email`, { required: 'Required' })} />
                           <Input
                              label={'Address'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].address &&
                                errors.oaMemberDTOList[i].address?.message}
                              topSpace
                              fullWidth
                              formStyle
                              defaultValue={'132, My Street,'}
                              {...register(`oaMemberDTOList.${i}.address`, {
                                required: 'Required',
                                pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                              })} 
                            />
                            <NameWrapper>
                            <Input
                              label={'State'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].state &&
                                errors.oaMemberDTOList[i].state?.message}
                              topSpace
                              fullWidth
                              defaultValue={'Delaware'}

                              formStyle
                              {...register(`oaMemberDTOList.${i}.state`, {
                                required: 'Required',
                                pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                              })} 
                            />
                            <Input
                              label={'Country'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].country &&
                                errors.oaMemberDTOList[i].country?.message}
                              topSpace
                              fullWidth
                              formStyle
                              defaultValue={'United States'}

                              {...register(`oaMemberDTOList.${i}.country`, {
                                required: 'Required',
                                pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                              })} 
                              />
                          </NameWrapper>
                          <NameWrapper>
                            <Input
                              label={'City'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].city &&
                                errors.oaMemberDTOList[i].city?.message}
                              topSpace
                              fullWidth
                              defaultValue={'Walnut'}
                              formStyle
                              {...register(`oaMemberDTOList.${i}.city`, {
                                required: 'Required',
                                pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                              })} 
                            />
                            <Input
                              label={'Zip Code'}
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].zipCode &&
                                errors.oaMemberDTOList[i].zipCode?.message}
                              topSpace
                              fullWidth
                              defaultValue={'12345'}
                              formStyle
                              {...register(`oaMemberDTOList.${i}.zipCode`, {
                                required: 'Required',
                                pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                              })} 
                              />
                          </NameWrapper>
                            <CheckBoxWrapper>
                              <div style={{ fontSize: '1rem', fontWeight: 400, color: 'var(--text-primary)', marginBottom: '0.75em' }}>
                              Type of initial contribution of Member
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Checkbox
                                    active={cashContribution}
                                    onClick={() => onClickCashContribution(!cashContribution, i)}
                                  />
                                  <CheckboxLabel dark>Cash contribution</CheckboxLabel>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Checkbox active={true} onClick={() => onClickNonCashContribution(!nonCashContribution, i)} />
                                  <CheckboxLabel dark>Non-cash contribution</CheckboxLabel>
                                </div>
                              </div>
                            </CheckBoxWrapper>
                            {cashContribution && <Input
                              type="text"
                              label={'How much cash will the Member initially contribute to the LLC?'}
                              topSpace
                              placeholder='$0.00'
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].cashContributionAmount &&
                                errors.oaMemberDTOList[i].cashContributionAmount?.message}
                              fullWidth
                              formStyle
                              defaultValue={'$500,000,000.00'}
                              {...register(`oaMemberDTOList.${i}.cashContributionAmount`, { required: 'Required' })} />}
                            {nonCashContribution &&
                            
                              <>
                              <Label style={{ fontSize: '1rem', fontWeight: 'normal', color: 'var(--text-primary)', marginTop: '2em' }}>How much non-cash will the Member initially contribute to the LLC?</Label>
                              <AddItemWrapper>
                                    {fields.map((item, j) => {
                                      return (
                                        <ItemWrapperContribution>
                                          <Input
                                            fullWidth
                                            formStyle
                                            placeholder="Contribution Type"
                                            {...register(`oaMemberDTOList.${i}.oaNonCashContributionDTOList.${j}.contributionType`, { required: 'Required' })}
                                          />
                                          <Input
                                            type="number"
                                            fullWidth
                                            formStyle
                                            placeholder="$0.00"
                                            {...register(`oaMemberDTOList.${i}.oaNonCashContributionDTOList.${j}.contributionAmount`, { required: 'Required' })}
                                          />  
                                          
                                          <ItemMenuButton onClickRemove={() => remove(j)} />
                                        </ItemWrapperContribution>
                                      )
                                    })}
                                    <ItemWrapper>
                                      <TextButton
                                        style={{ fontSize: 14 }}
                                        onClick={() =>
                                          append({
                                            contributionType: "",
                                            contributionAmount: ""
                                          })
                                        }
                                      >
                                        + ADD CONTRIBUTION
                                      </TextButton>
                                      <div />
                                      <div />
                                    </ItemWrapper>
                                  </AddItemWrapper>
                              {/* <ContributionArray nestIndex={i} {...{ control, register, errors }} /> */}
                              </>}
                            <Input
                              type="number"
                              placeholder='0%'
                              label={'Ownership / percentage'}
                              topSpace
                              error={errors.oaMemberDTOList &&
                                errors.oaMemberDTOList[i] &&
                                errors.oaMemberDTOList[i].ownershipPercentage &&
                                errors.oaMemberDTOList[i].ownershipPercentage?.message}
                              fullWidth
                              formStyle
                              defaultValue={'100'}
                              {...register(`oaMemberDTOList.${i}.ownershipPercentage`, { required: 'Required',pattern :{value:/^100$|^[0-9]{1,2}$|^[0-9]{1,2}\.[0-9]{1,3}$/, message : "Percentage must be between 0-100" }})} />
                          </>}
                      </Body>
                    </div>
                  </Collapsible>
                </WrapperList>
              </div>
            </ItemWrapper>
          )
        })}
        <Select
          label={'Frequency of LLC distribution of profits'}
          style={{ width: '100%' }}
          formStyle
          topSpace
          bottomSpace
          error={errors.profitDistributionFrequency &&
            errors.profitDistributionFrequency?.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue(`profitDistributionFrequency`, value)
          }}
          options={[{ label: 'Anytime member decides/ Anytime all members unanimously decide', value: 'ANYTIME_MEMBER_DECIDES' },
          { label: 'Board decides', value: 'BOARD_DECIDES' },
          { label: 'Monthly', value: 'MONTHLY' },
          { label: 'Quarterly', value: 'QUARTERLY' },
          { label: 'Semi-annually', value: 'SEMI_ANNUALLY' },
          { label: 'Annually', value: 'ANNUALLY' }]}
          {...register(`profitDistributionFrequency`, { required: 'Required' })}
        />
        <Select
          label={"End of LLC's fiscal year"}
          style={{ width: '100%' }}
          formStyle
          bottomSpace
          error={errors.fiscalYear &&
            errors.fiscalYear?.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue(`fiscalYear`, value)
          }}
          options={[{ label: 'January', value: 'JANUARY' },
          { label: 'February', value: 'FEBRUARY' },
          { label: 'March', value: 'MARCH' },
          { label: 'April', value: 'APRIL' },
          { label: 'May', value: 'MAY' },
          { label: 'June', value: 'JUNE' },
          { label: 'July', value: 'JULY' },
          { label: 'August', value: 'AUGUST' },
          { label: 'September', value: 'SEPTEMBER' },
          { label: 'October', value: 'OCTOBER' },
          { label: 'November', value: 'NOVEMBER' },
          { label: 'December', value: 'DECEMBER' },]}
          {...register(`fiscalYear`, { required: 'Required' })}
        />
        <Select
          label={"Tax election treatment from the IRS"}
          style={{ width: '100%' }}
          formStyle
          bottomSpace
          error={errors.irsTaxTreatment &&
            errors.irsTaxTreatment?.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue(`irsTaxTreatment`, value)
          }}
          options={[{ label: 'None', value: 'NONE' },
          { label: 'C-corp', value: 'C_CORP' },
          { label: 'S-corp', value: 'S_CORP' },
          ]}
          {...register(`irsTaxTreatment`, { required: 'Required' })}
        />
          <Label style={{ marginBottom: '1.5em', marginTop: '1em' }}>Decision-making</Label>
          <Select
            label={'How are voting rights calculated?'}
            style={{ width: '100%' }}
            formStyle
            bottomSpace
            error={errors.votingRight && errors.votingRight.message}
            disabled={false}
            onSelectOption={(value) => {
              setValue('votingRight', value)
            }}
            options={[{ label: 'Proportional to the ownership interest (Recommended)', value: 'PROPORTIONAL' }, { label: 'Every member gets a vote', value: 'MEMBER_GETS_VOTE' }]}
            {...register("votingRight", { required: 'Required' })}
          />
          <RadioList
            name="memberDecision"
            label={'In general, how are decisions made by the members?'}
            bottomSpace
            options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
            value={'UNANIMOUS'}
            onSelect={onDecisionTypeSelection}
            rowSpan={true}
          />
          <RadioList
            name="decisionToAddNewMember"
            label={'To add additional members to the LLC, decision by remaining members should be'}
            bottomSpace
            options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
            value={'UNANIMOUS'}
            onSelect={onDecisionToAddNewMember}
            rowSpan={true}
          />
          <RadioList
            name="decisionToRemoveMember"
            label={'To remove a member from the LLC, decision by remaining members should be'}
            bottomSpace
            options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
            value={'UNANIMOUS'}
            onSelect={onDecisionToRemoveMember}
            rowSpan={true}
          />
          <RadioList
            name="decisionToAddContribution"
            label={'Whether to make members obligated to make additional contributions, decision by members should be'}
            bottomSpace
            options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
            value={'UNANIMOUS'}
            onSelect={onDecisionToAddContribution}
            rowSpan={true}
          />
          <RadioList
            name="decisionToDissolveLLC"
            label={'What vote will be required by members to dissolve the LLC?'}
            bottomSpace
            options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
            value={'UNANIMOUS'}
            onSelect={onDecisionToDissolveLLC}
            rowSpan={true}
          />
          <RadioList
                name="decisionToAppointManager"
                label={'To appoint a manager, decision by members should be:'}
                bottomSpace
                options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
                value={'UNANIMOUS'}
                onSelect={onDecisionToAppointManager}
                rowSpan={true} />
          <RadioList
            name="decisionToMakeBoardDecision"
            label="What level of consent is required from the managers to make a board decision?"
            bottomSpace
            options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
            value={'UNANIMOUS'}
            onSelect={onDecisionToMakeBoard}
            rowSpan={true} />
        <Label style={{ marginBottom: '24px' }}>Changes</Label>
        <Select
          label={'What is required to make amendments to OA?'}
          style={{ width: '100%' }}
          formStyle
          bottomSpace
          error={errors.decisionToAmendOA && errors.decisionToAmendOA.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue('decisionToAmendOA', value)
          }}
          options={[{ label: 'Consent of members/member', value: 'MEMBER' }, { label: 'Consent of members/member and board of managers', value: 'MEMBER_AND_BOARD' }]}
          {...register("decisionToAmendOA", { required: 'Required' })}
        />
        <Input
          label={'In the future, if a member withdraws from the LLC, how many days will the LLC have to buy the withdrawn membership interest and split it among all members?'}
          error={errors.daysToBuyWtdrwnIntrst && errors.daysToBuyWtdrwnIntrst.message}
          bottomSpace
          fullWidth
          formStyle
          value={'30'}
          {...register("daysToBuyWtdrwnIntrst", { required: 'Required' })}
        />
        <Select
          label={"How will a withdrawing member's membership interest be split?"}
          style={{ width: '100%' }}
          formStyle
          bottomSpace
          error={errors.oaMembershipInterestSplit && errors.oaMembershipInterestSplit.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue('oaMembershipInterestSplit', value)
          }}
          options={[{ label: 'Equally between remaining members', value: 'EQUALLY' }, { label: 'Proportionally between remaining members', value: 'PROPORTIONALLY' }]}
          {...register("oaMembershipInterestSplit", { required: 'Required' })}
        />
        <Input
          label={"If the LLC members choose not to buy out the withdrawing member's interest as a group, how many days will be given for individual member(s) to buy the interest AFTER the joint buy-out period has expired?"}
          error={errors.daysToBuyIntrstAftrJntPrd && errors.daysToBuyIntrstAftrJntPrd.message}
          bottomSpace
          fullWidth
          formStyle
          value={'30'}
          {...register("daysToBuyIntrstAftrJntPrd", { required: 'Required' })}
        />
        <Select
          label={'Should you allow a member to be removed by other members?'}
          style={{ width: '100%' }}
          formStyle
          bottomSpace
          error={errors.removeMbrByOthrMbr && errors.removeMbrByOthrMbr.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue('removeMbrByOthrMbr', value)
          }}
          options={[{ label: 'Yes(recommended)', value: 'YES' }, { label: 'No', value: 'NO' }]}
          {...register("removeMbrByOthrMbr", { required: 'Required' })}
        />
        <Select
          label={'What vote will be required to dissolve the LLC?'}
          style={{ width: '100%' }}
          formStyle
          bottomSpace
          error={errors.voteToDissolve && errors.voteToDissolve.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue('voteToDissolve', value)
          }}
          options={[{ label: 'Unanimous', value: 'UNANIMOUS' }, { label: 'Majority', value: 'MAJORITY' }]}
          {...register("voteToDissolve", { required: 'Required' })}
        />
        <Input
          label={'To discuss dissolution in a meeting, how many days of notice should be given to all the members?'}
          error={errors.noticePrdToDissolution && errors.noticePrdToDissolution.message}
          bottomSpace
          fullWidth
          formStyle
          value={'31'}
          {...register("noticePrdToDissolution", { required: 'Required' })}
        />
      </FormWrapper>
    </Form>
  )
}

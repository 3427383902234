import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { Hero, HeroType } from '../components/Hero'
import { Card, CardStatus } from '../components/Card'
import { CardItem } from '../components/CardItem'
import { Stepper, StepperItem } from '../components/Stepper'
import { IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { Input } from '../components/Input'
import MediaQuery from 'react-responsive'

const STEPPER_ITEMS = [
  {
    active: true,
    title: 'State Filings ',
    subtitle: '12 Hours',
    icon: IconType.document,
  },
  {
    active: false,
    title: 'Communication',
    subtitle: '6 Hours',
    icon: IconType.addressBook,
  },
  {
    active: false,
    title: 'EIN (Tax ID)',
    subtitle: '12 Hours',
    icon: IconType.money,
  },
  {
    active: false,
    title: 'Bank Account',
    subtitle: '1 Hour',
    icon: IconType.winner,
  },
]

export const Home = () => (
  <PageLayout title="Home">
    <Hero type={HeroType.start} />
    <MediaQuery query="(min-width:728px)">
      <Stepper>
        {STEPPER_ITEMS.map((item, i) => (
          <StepperItem {...item} first={i === 0} last={i === STEPPER_ITEMS.length - 1} />
        ))}
      </Stepper>
    </MediaQuery>
    <Card title="Communication" subtitle="1 day remaining">
      <CardItem
        title="US Address"
        description={`2390  Argonne Street, New Castle
          Delaware, United States -19720`}
        status={CardStatus.success}
      />
      <CardItem title="US Phone Number" status={CardStatus.pending} noBottomSpace />
    </Card>
    <Card title="Bank Account" subtitle="1 day remaining">
      <CardItem
        title="Bank Account"
        description="Your bank account is ready, create a username and password. Now you may need to upload your personal identification."
        status={CardStatus.pending}
        noBottomSpace
      >
        <Button>ACTIVATE ACCOUNT</Button>
      </CardItem>
    </Card>
    <Card title="Incorporation" subtitle="4 days remaining">
      <CardItem
        title="Company name"
        description="ABC Programs Inc."
        status={CardStatus.failure}
        badgeLabel="Name not available"
      >
        <>
          <MediaQuery query="(min-width:728px)">
            <div style={{ display: 'flex' }}>
              <Input
                value="ABC Programs Inc."
                placeholder="Company name"
                style={{ marginRight: 24 }}
              />
              <Button onClick={() => console.log('DERP')}>UPDATE</Button>
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width:728px)">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
              <Input
                value="ABC Programs Inc."
                placeholder="Company name"
                style={{ width: '100%' }}
              />
              <Button onClick={() => console.log('DERP')}>UPDATE</Button>
            </div>
          </MediaQuery>
        </>
      </CardItem>
      <CardItem title="Preparation of documents for e-filing" status={CardStatus.pending} />
      <CardItem title="E-filing in Delaware State" status={CardStatus.pending} />
      <CardItem title="Certificate of Incorporation" status={CardStatus.pending} />
      <CardItem title="Application for EIN" status={CardStatus.pending} noBottomSpace />
    </Card>
  </PageLayout>
)
